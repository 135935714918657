<template>
  <div>
    <div class="font-size-16 text-dark mb-4">
      Browse {{ $route.meta.title }}
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
.tour-site-cards {
  display: flex;
  flex-direction: row;
  align-self: auto;
}
</style>
